export enum EnvType {
  local = "LOCAL",
  dev = "DEV",
  ci = "CI",
  qa = "QA",
  uat = "UAT",
  prod = "PROD",
}
export const environments = {
  local: {
    env: EnvType.local,
    name: "local",
    apiUrl: "https://localhost:44362",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV,
  },
  dev: {
    env: EnvType.dev,
    name: "dev",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV,
  },
  ci: {
    env: EnvType.ci,
    name: "ci",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV,
  },
  qa: {
    env: EnvType.qa,
    name: "qa",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_PROD,
  },
  uat: {
    env: EnvType.uat,
    name: "uat",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV,
  },
  prod: {
    env: EnvType.prod,
    name: "production",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_PROD,
  },
};

export function getEnvironment(): any {
  // Default to PROD
  let environment = environments.prod;
  const host = window.location.hostname;

  if (host.includes("localhost")) {
    environment = environments.local;
  } else if (host.includes("-dev")) {
    environment = environments.dev;
  } else if (host.includes("-ci")) {
    environment = environments.ci;
  } else if (host.includes("-qa")) {
    environment = environments.qa;
  } else if (host.includes("-uat")) {
    environment = environments.uat;
  } else {
    // default to PROD
    environment = environments.prod;
  }

  // disable logging for UAT and PROD environments
  if (environment.env === EnvType.uat || environment.env === EnvType.prod) {
    console.log = () => {};
    console.warn = () => {};
    console.debug = () => {};
    console.info = () => {};
  }
  return environment;
}
