import { Module } from "vuex";
import { DocumentResponseViewModel } from "@/models/viewModels";

const documentsModule: Module<any, any> = {
  namespaced: true,
  state: {
    ASDDocuments: [] as DocumentResponseViewModel[],
    ASDDocumentId: 0,
  },
  mutations: {
    SET_ASD_DOCUMENTS(state: any, documents: any) {
      state.ASDDocuments = documents;
    },
    SET_ASD_DOCUMENT_ID(state: any, documentId: number) {
      state.ASDDocumentId = documentId;
    },
  },
  actions: {
    setASDDocuments({ commit }, payload) {
      commit("SET_ASD_DOCUMENTS", payload);
    },
    setASDDocumentId({ commit }, payload) {
      commit("SET_ASD_DOCUMENT_ID", payload);
    },
  },
  getters: {},
};

export default documentsModule;
