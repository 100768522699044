
import { defineComponent } from "vue";
import { mapActions, mapState } from "vuex";
import { $api } from "@/services/api";
import * as jsonPatch from "fast-json-patch";
import useLeftPanelText from "@/use/leftPanelText";
import ApplicationPageRefi from "@/components/shared/ApplicationPageRefi.vue";
import ApplicationSteps from "@/components/shared/ApplicationSteps.vue";
import ApplicationStepRefi from "@/components/shared/ApplicationStepRefi.vue";
import CircleLoader from "@/components/shared/CircleLoader.vue";
import {
  AppData,
  Applicant,
  ApplicantObserver,
  Application,
  ComparatorFunction,
  State,
  Transition,
  Validation,
} from "@/models/application";

export default defineComponent({
  setup() {
    const { getCurrentText } = useLeftPanelText();
    return { getCurrentText };
  },
  components: {
    ApplicationPageRefi,
    ApplicationSteps,
    ApplicationStepRefi,
    CircleLoader,
  },
  data: () => ({
    currentStep: 1,
    path: "",
    loading: false,
    previousLoading: false,
    statesPath: [] as State[],
    compareAppData: {} as AppData,
    appObserver: {} as jsonPatch.Observer<Application>,
    applicantObservers: [] as ApplicantObserver[],
    updatedApplicant: undefined as any,
  }),
  computed: {
    ...mapState(["appData", "leftPanelText"]),
    states(): any[] {
      return this.appData.statesSeen || [];
    },
    stepScroll(): string {
      return `${(this.currentStep - 1) * 100}%`;
    },
  },
  methods: {
    ...mapActions(["setAppData", "setLeftPanelText"]),
    async previous(event: any) {
      this.previousLoading = true;
      this.appData.currentState = this.appData.previousState;
      this.appData.statesSeen.push(this.appData.currentState);

      // Update currentStep index first update statesPath
      const newStates = this.statesPath;

      // Push the current state to the stack if we are at 0
      // If the length is greater than 1 we already have what we need
      if (newStates.length <= 1) {
        newStates.unshift(this.appData.currentState);
        this.currentStep--;
      }

      this.statesPath = newStates;

      // Set the current state order
      this.appData.currentState.order = this.appData.statesSeen.length - 1;
      await this.updateAppData();

      this.updateLeftPanel();
      this.currentStep--;
      this.statesPath.pop();
      this.previousLoading = false;
      // Automatically refresh component to get around the Uncaught TypeError
      location.reload();
      console.log("PREVIOUS COMPLETE", this.currentStep, this.statesPath);
    },
    async stepComplete(data: any) {
      this.loading = true;
      console.log("stepComplete() event data", data);

      // 1. Verify what the current state is, and compare it to the last item in statesSeen
      // 2. If last state seen, grab the right transition and move it into statesSeen
      if ((this.appData.transitions || []).length > 0) {
        let state: any;

        if (this.appData.transitions.length > 1) {
          let stateSet = false;
          this.appData.transitions = this.appData.transitions.sort((a: any, b: any) => {
            if (a.comparatorFunction === null) {
              return 1;
            }
            if (b.comparatorFunction === null) {
              return -1;
            }
            if (b.comparatorFunction) {
              return 0;
            }
          });

          this.appData.transitions.forEach((transition: Transition, index: number) => {
            if (stateSet) {
              return;
            }

            if (transition.comparatorFunction && transition.comparatorFunction.trim() !== "") {
              try {
                const comparison: ComparatorFunction = JSON.parse(transition.comparatorFunction);
                if (comparison.validation && comparison.validation.length > 0) {
                  let valid = comparison.type === "AND";
                  comparison.validation.forEach((validation: Validation) => {
                    let compareData = data[validation.property];

                    if (compareData && typeof compareData === "string") {
                      compareData = compareData.trim();
                    }

                    // Verify which type of comparison we need to perform
                    const compareValue =
                      validation.operator === "ne"
                        ? compareData !== validation.value
                        : compareData === validation.value;

                    // Take comapreValue and append it to current valid state
                    valid = comparison.type === "AND" ? valid && compareValue : valid || compareValue;
                  });

                  if (valid) {
                    state = transition;
                    stateSet = true;
                  }
                }
              } catch (ex) {
                // not proper JSON stored. Just skipping
                console.error("Could not parse comparator function", ex);
              }
            }

            // Make sure a transition gets added no matter what
            if (state === undefined && index === this.appData.transitions.length - 1) {
              state = transition;
              stateSet = true;
            }
          });
        } else {
          state = this.appData.transitions[0];
        }

        if (state !== undefined) {
          this.appData.statesSeen.push({
            name: state.name,
            route: state.route,
            fsStateID: state.fsStateID,
            // Get current length since order starts at 0
            order: this.appData.statesSeen.length,
            programTypeId: state.programTypeId,
          });
          this.appData.previousState = this.appData.currentState;
        }
      }

      if (data?.updatedPerson) {
        this.updatedApplicant = data.updatedPerson;
      }

      // 4. Update the Application data
      await this.updateAppData();

      // Add step first in an attempt to provide cleaner transition
      this.statesPath.push(this.appData.currentState);
      this.currentStep++;

      console.log("STEP COMPLETE", this.currentStep, this.statesPath);

      // Clear query after first step / after starting a new application
      let query = Object.assign({}, this.$route.query);
      if (query?.lid && query?.productTypeId && query?.leadSourceId) {
        localStorage.setItem("product", `${query?.productTypeId}`);

        delete query?.leadSourceId;
        delete query?.productTypeId;
        delete query?.programTypeId;
        delete query?.lid;

        this.$router.replace({ query });
      }

      this.loading = false;
    },
    isCurrent(state: State) {
      const current = this.appData.currentState;
      return current.fsStateID === state.fsStateID && current.order === state.order;
    },
    updateLeftPanel() {
      this.setLeftPanelText(this.getCurrentText(this.appData.currentState?.route || ""));
    },
    setAppDataDisplay() {
      //Pull application out of state
      if (this.appData) {
        this.refreshObservers();

        if (this.appData.currentState && this.statesPath.length <= 0) {
          this.statesPath = [this.appData.currentState];
        }

        this.updateLeftPanel();
      }
    },
    refreshObservers() {
      if (this.appData) {
        if (this.appData.application) {
          if (this.appData.application.schoolConfiguration === null) {
            this.appData.application.schoolConfiguration = undefined;
          }

          this.appObserver = jsonPatch.observe(this.appData.application);
        }

        if (this.appData.applicants && this.appData.applicants.length > 0) {
          // Loop over array and create observers for each
          this.applicantObservers = this.appData.applicants.map((applicant: Applicant) => {
            if (applicant.contactInfo === null) {
              applicant.contactInfo = undefined;
            }
            if (applicant.spouse === null) {
              applicant.spouse = undefined;
            }
            if (applicant.concierge === null) {
              applicant.concierge = undefined;
            }

            return {
              applicantId: applicant.id,
              observer: jsonPatch.observe<Applicant>(applicant),
            };
          });
        }
      }
    },
    // person is an optional param
    async updateAppData(person: any = undefined) {
      //precaution for CoreDataApi
      if (this.appData.applicationId === undefined && this.appData.appId) {
        this.appData.applicationId = this.appData.appId;
        console.log("SETTING APP ID FOR COREDATAAPI", this.appData.applicationId);
      }

      // 1. Gather the Application Patch object
      console.log("Gather the Application Patch object", this.appData.application);
      const applicationPatch = jsonPatch.generate(this.appObserver);
      console.log("APPLICATION PATCH", applicationPatch);

      const applicantPatches: any[] = [];

      // 2. Gather any Applicant patches
      if (this.updatedApplicant) {
        console.log("UPDATED APPLICANT PATCH FUNCTIONALITY");
        console.log("this.updatedApplicant", this.updatedApplicant);
        let applicant = { id: this.updatedApplicant.id } as any;
        const currentApplicantObserver = jsonPatch.observe<Applicant>(applicant);

        applicant.firstName = this.updatedApplicant?.firstName;
        applicant.middleName = this.updatedApplicant?.middleName;
        applicant.lastName = this.updatedApplicant?.lastName;
        applicant.nationalIdNumber = this.updatedApplicant?.nationalIdNumber;
        applicant.nationalIdNumberTypeId = this.updatedApplicant?.nationalIdNumberTypeId;
        applicant.citizenshipStatusId = this.updatedApplicant?.citizenship;
        applicant.dateOfBirth = this.updatedApplicant?.dateOfBirth;
        applicant.contactInfo = this.updatedApplicant?.contactInfo;
        applicant.concierge = this.updatedApplicant?.concierge;

        // Spouse step
        if (applicant.spouse) {
          applicant.spouse = this.updatedApplicant?.spouse;
        }

        // For future iterations
        // applicant =Object.entries(this.updatedApplicant);
        // for (const [key, value] of Object.entries(this.updatedApplicant)) {
        //   applicant[key] = value;
        // }
        const patch = jsonPatch.generate(currentApplicantObserver);

        applicantPatches.push({
          applicantId: applicant.id,
          patch,
        });
        this.updatedApplicant = undefined;
      }
      // catches other patches the other one does not get
      this.applicantObservers.forEach((ob: ApplicantObserver) => {
        const patch = jsonPatch.generate(ob.observer);

        if (patch.length > 0) {
          applicantPatches.push({
            applicantId: ob.applicantId,
            patch,
          });
        }
      });

      console.log("Applicant Patches", applicantPatches);

      const updatedAppData = await $api.applications.updateApplication({
        applicationId: this.appData.id,
        applicationPatch,
        applicantPatches,
        currentState: this.appData.currentState,
        previousState: this.appData.previousState,
        statesSeen: this.appData.statesSeen,
        transitions: this.appData.transitions,
      });

      console.log("updateAppData() UPDATED APP", updatedAppData);

      if (!updatedAppData?.error || !updatedAppData.id) {
        await this.setAppData(updatedAppData);
      } else {
        alert("Something has gone wrong");
      }
    },
  },
  watch: {
    appData() {
      console.log("Application Update");
      this.setAppDataDisplay();
    },
  },
  unmounted() {
    if (localStorage.getItem("cache.path")) {
      localStorage.removeItem("cache.path");
    }
    if (localStorage.get("leadSourceId")) {
      localStorage.removeItem("leadSourceId");
    }

    if (localStorage.getItem("product")) {
      localStorage.removeItem("product");
    }
  },
});
