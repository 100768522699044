export default function useLeftPanelText(): any {
  function getCurrentText(state: string): string {
    switch (state) {
      case "chooseBorrowerCoborrower":
      case "dgChooseBorrowerCoborrower":
        return "Whatever the dream is, let's make it happen!";
      case "refiChooseBorrowerCoSigner":
        return "Fund your future with a credit union!";
      case "schoolInformation":
      case "dgSchoolInformation":
        return "Investing in your future is a smart choice.";
      case "refiSchoolInformation":
        return "Take control of your student loan repayment.";
      case "coborrowerPersonalInfo":
      case "coborrowerInvitedApplicantInfo":
      case "borrowerPersonalInfo":
      case "borrowerInvitedApplicantInfo":
        return "You're going strong! Get a rate quote instantly.";
      case "refiCosignerPersonalInfo":
      case "refiBorrowerPersonalInfo":
      case "refiBorrowerInvitedApplicantInfo":
      case "refiCosignerInvitedApplicantInfo":
        return "Enter your info to view estimated rates.";
      case "coborrowerPersonalInfoContinued":
      case "coborrowerInvitedApplicantInfoContinued":
      case "borrowerPersonalInfoContinued":
      case "borrowerInvitedApplicantInfoContinued":
        return "You're going strong! Get a rate quote instantly.";
      case "refiCosignerPersonalInfoContinued":
      case "refiBorrowerPersonalInfoContinued":
      case "refiBorrowerInvitedApplicantInfoContinued":
      case "refiCosignerInvitedApplicantInfoContinued":
        return "Enter your info to view estimated rates.";
      case "showMyRates":
        return "Simple, flexible options to get you going. Take the next step now!";
      case "refiShowMyRates":
        return "Flexible refinance options to best meet your needs.";
      case "coborrowerRegistration":
      case "refiCosignerRegistration":
        return "You're almost there. Set up your account and keep rolling!";
      case "borrowerAdditionalInfo":
      case "dgBorrowerAdditionalInfo":
      case "dgCoborrowerAdditionalInfo":
      case "coborrowerAdditionalInfo":
      case "coborrowerMaritalStatus":
      case "borrowerMaritalStatus":
      case "refiBorrowerMaritalStatus":
      case "refiBorrowerUnderlyingLoansToRefinance":
      case "refiBorrowerAdditionalInfo":
      case "refiCosignerMaritalStatus":
      case "refiCosignerUnderlyingLoansToRefinance":
      case "refiCosignerAdditionalInfo":
        return "Just a quick check of some important information.";
      case "coborrowerHardPullConsent":
      case "refiCosignerHardPullConsent":
        return "Isn't it nice knowing we're on your side?";
      case "borrowerInfo":
      case "borrowerInfoContinued":
      case "refiBorrowerInfo":
      case "refiBorrowerInfoContinued":
        return "You're helping make their college dream a reality. Thank you.";
      case "borrowerInvited":
      case "refiBorrowerInvited":
        return "It's time to make your college dream a reality.";
      case "coborrowerInvited":
      case "refiCosignerInvited":
        return "You're helping make their college dream a reality. Thank you.";
      case "borrowerRegistration":
        return "Set up your unique student borrower account and we're on our way.";
      case "refiBorrowerRegistration":
        return "Let's set up your account for this application.";
      case "borrowerHardPullConsent":
      case "refiBorrowerHardPullConsent":
        return "You're almost there!";
      case "addCoborrower":
      case "dgAddCoborrower":
      case "refiAddCosigner":
        return "Questions about the next steps? We're here to help.";
      case "coborrowerInfo":
      case "coborrowerInfoContinued":
        return "Great work. Now let's verify your co-borrower's information.";
      case "refiCosignerInfo":
      case "refiCosignerInfoContinued":
        return "Great work. Now let's verify your cosigner's information.";
      case "approval":
      case "dgApproval":
      case "loanConfiguration":
      case "refiApproval":
      case "refiLoanConfiguration":
      case "refiShowMyRatesExit":
      default:
        return "";
    }
  }
  // Placeholder for the REFI routes TBD
  function getCurrentTextByProductId(state: string, productTypeId: number) {
    if (productTypeId === 2) {
      switch (state) {
        case "refiChooseBorrowerCoSigner":
          return "Fund your future with a credit union!";
        case "refiSchoolInformation":
          return "Take control of your student loan repayment.";
        case "refiCosignerPersonalInfo":
          return "Enter your info to view estimated rates.";
        case "refiBorrowerPersonalInfo":
          return "Enter your info to view estimated rates.";
        case "refiCosignerPersonalInfoContinued":
          return "Enter your info to view estimated rates.";
        case "refiBorrowerPersonalInfoContinued":
          return "Enter your info to view estimated rates.";
        case "refiShowMyRates":
          return "Flexible refinance options to best meet your needs.";
        case "refiBorrowerRegistration":
          return "Let's set up your account for this application.";
        case "refiBorrowerMaritalStatus":
          return "Just a quick check of some important information.";
        case "refiBorrowerUnderlyingLoansToRefinance":
          return "Just a quick check of some important information.";
        case "refiBorrowerAdditionalInfo":
          return "Just a quick check of some important information.";
        case "refiAddCosigner":
          return "Questions about the next steps? We're here to help.";
        case "refiBorrowerHardPullConsent":
          return "You're almost there!";
        case "refiCosignerInfo":
          return "Great work. Now let's verify your cosigner's information.";
        case "refiBorrowerInfo":
          return "You're helping make their college dream a reality. Thank you.";
        case "refiBorrowerInvited":
          return "It's time to make your college dream a reality.";
        case "refiBorrowerInvitedApplicantInfo":
          return "Enter your info to view estimated rates.";
        case "refiBorrowerInvitedApplicantInfoContinued":
          return "Enter your info to view estimated rates.";
        case "refiBorrowerInfoContinued":
          return "You're helping make their college dream a reality. Thank you.";
        case "refiApproval":
          return "";
        case "refiLoanConfiguration":
          return "";
        case "refiCosignerInvited":
          return "You're helping make their college dream a reality. Thank you.";
        case "refiCosignerInfoContinued":
          return "Great work. Now let's verify your cosigner's information.";
        case "refiCosignerInvitedApplicantInfo":
          return "Enter your info to view estimated rates.";
        case "refiCosignerInvitedApplicantInfoContinued":
          return "Enter your info to view estimated rates.";
        case "refiCosignerRegistration":
          return "You're almost there. Set up your account and keep rolling!";
        case "refiCosignerMaritalStatus":
          return "Just a quick check of some important information.";
        case "refiCosignerUnderlyingLoansToRefinance":
          return "Just a quick check of some important information.";
        case "refiCosignerAdditionalInfo":
          return "Just a quick check of some important information.";
        case "refiCosignerHardPullConsent":
          return "Isn't it nice knowing we're on your side?";
        case "refiShowMyRatesExit":
          return "";
        default:
          return "";
      }
    }
  }
  function getApprovalStepText(status: number): string {
    switch (status) {
      case 2:
        return "We'll be in touch soon.";
      case 5:
        return "Thank you for giving us the opportunity.";
      default:
        return "Need help with next steps? Let us know.";
    }
  }
  function getLoanConfigurationText(substep: number) {
    switch (substep) {
      case 1:
        return "You're making a smart choice.";
      case 2:
        return "Tell us how much you need.";
      case 3:
        return "Choose wisely and reach out if you have questions.";
      case 4:
        return "Nice! Now go get that degree.";
      default:
        return "";
    }
  }
  function getRefiLoanConfigurationText(substep: number) {
    switch (substep) {
      case 1:
        return "Select your rate and repayment term.";
      default:
        return "You're ready for the next steps. Let's do this!";
    }
  }
  return {
    getCurrentText,
    getLoanConfigurationText,
    getApprovalStepText,
    getRefiLoanConfigurationText,
    getCurrentTextByProductId,
  };
}
