
import { defineComponent } from "vue";
export default defineComponent({
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Borrower Application";
      },
    },
  },
});
