
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    closeBtn: {
      type: Boolean,
      default: true,
    },
  },
  name: "Modal",
  beforeMount() {
    // When displaying a modal we tell the body that we don't
    // want the background to scroll
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "hidden";
    }
  },
  beforeUnmount() {
    // Right before we exit the modal we tell the body to perform as it did before
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "visible";
    }
  },
});
