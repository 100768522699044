import { Module } from "vuex";
import { $api } from "@/services/api";
import store from "@/store";
import useFormatter from "@/use/formatter";
import useLeftPanelText from "@/use/leftPanelText";

const { formatNumber } = useFormatter();
const { getApprovalStepText } = useLeftPanelText();

const applicationDecisionModule: Module<any, any> = {
  namespaced: true,
  state: {
    underReview: undefined as undefined | boolean,
    approved: false as boolean,
    approval: undefined as undefined | boolean,
    applicationStatus: 0 as number | string,
    gettingApplicationDecision: true as boolean,
    conciergeRadioButtonOptions: [
      {
        label: "No, thanks",
        value: false,
      },
      {
        label: "Yes",
        value: true,
      },
    ],
    preferredContactMethodTypes: [],
    preferredContactMethodOptions: [],
  },
  mutations: {
    SET_APPLICATION_STATUS(state: any, applicationStatus: number) {
      state.applicationStatusId = applicationStatus;
    },
    SET_PREFERRED_CONTACT_METHOD_TYPES(state: any, contactMethodTypes: any) {
      state.preferredContactMethodTypes = contactMethodTypes;
    },
    SET_PREFERRED_CONTACT_METHOD_OPTIONS(state: any, contactMethodOptions: any) {
      state.preferredContactMethodOptions = contactMethodOptions;
    },
    SET_GETTING_APPLICATION_DECISION(state: any, flag: boolean) {
      state.getApplicationDecision = flag;
    },
    SET_PREQUALIFIED_HEADER_MESSAGE(state: any, loanAmount: any) {
      state.headerMessage = `You're pre-qualified for up to $${formatNumber(loanAmount, 0)}.`;
    },
    SET_STEP_VALUES(state: any, applicationStatus: any) {
      switch (applicationStatus) {
        case 2:
          state.approval = false;
          state.approved = false;
          state.underReview = true;
          state.headerMessage = "Your application is currently under review.";
          state.bodyMessage =
            "No action is required at this time. We'll be in touch shortly with an update. Continue to your applicant portal for the most up-to-date status of your application. Thank you for giving us the opportunity to meet your education funding needs!";

          break;
        case 3:
          state.approved = true;
          state.approval = true;
          state.underReview = false;
          state.bodyMessage = "";
          state.headerMessage = ``;

          break;
        case 5:
          state.approval = false;
          state.approved = false;
          state.underReview = false;
          state.headerMessage = "Unfortunately, we were unable to approve your application.";
          state.bodyMessage =
            "Thank you for giving us the opportunity to meet your education finance needs. For full details on your application decision, please refer to your Adverse Action Notice which may be accessed in the My Documents section of your applicant portal soon.";

          break;
        case "Unknown":
          state.approved = false;
          state.approval = false;
          state.underReview = false;
          state.bodyMessage =
            "We will send your application decision to the email address(es) provided. You may close this browser tab.";
          break;
        default:
      }
    },
  },
  actions: {
    async getApprovalLookups({ commit, state }) {
      const lookups = await $api.lookups.getApprovalLookUps();
      if (lookups?.contactTypes) {
        commit("SET_PREFERRED_CONTACT_METHOD_TYPES", lookups.contactTypes);

        const options = state?.preferredContactMethodTypes?.map((option: any) => {
          return {
            label: option.name,
            value: option.id,
          };
        });

        commit("SET_PREFERRED_CONTACT_METHOD_OPTIONS", options);
      }
    },
    async prepareStep({ commit }, appStatus: any) {
      commit("SET_STEP_VALUES", appStatus);
    },
  },
  getters: {
    ApplicationStatusId(state) {
      return state.applicationStatus;
    },
    Approved(state: any) {
      return state.applicationStatus === 3;
    },
    UnderReview(state: any) {
      return state.applicationStatus === 2;
    },
    Approval(state) {
      return state.approval;
    },
    HeaderMessageText: (state: any): any => {
      switch (state.applicationStatus) {
        case 2:
          return "Your application is currently under review.";
        case 3:
          return `You're pre-qualified for up to $${formatNumber(store?.state?.appData?.application?.loanAmount, 0)}.`;
        case 5:
          return "Unfortunately, we were unable to approve your application.";
        case 1:
        case "Unknown":
          return "";
        default:
          return;
      }
    },
    BodyMessageText: (state: any): any => {
      switch (state.applicationStatus) {
        case 2:
          return "No action is required at this time. We'll be in touch shortly with an update. Continue to your applicant portal for the most up-to-date status of your application. Thank you for giving us the opportunity to meet your education funding needs!";
        case 1:
        case 3:
          return "";
        case 5:
          return "Thank you for giving us the opportunity to meet your education finance needs. For full details on your application decision, please refer to your Adverse Action Notice which may be accessed in the My Documents section of your applicant portal soon.";
        case "Unknown":
          return "We will send your application decision to the email address(es) provided. You may close this browser tab.";
        default:
          return;
      }
    },
    LeftPanelText: (state: any): any => {
      getApprovalStepText(state?.applicationStatus);
    },
  },
};

export default applicationDecisionModule;
