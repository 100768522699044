import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "previous-loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_circle_loader = _resolveComponent("circle-loader")!
  const _component_application_step = _resolveComponent("application-step")!
  const _component_application_steps = _resolveComponent("application-steps")!
  const _component_application_page = _resolveComponent("application-page")!

  return (_openBlock(), _createBlock(_component_application_page, null, _createSlots({
    default: _withCtx(() => [
      (_ctx.previousLoading || _ctx.currentStep > _ctx.statesPath.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_circle_loader)
          ]))
        : (_openBlock(), _createBlock(_component_application_steps, {
            key: 1,
            "step-scroll": _ctx.stepScroll
          }, {
            default: _withCtx(() => [
              (_ctx.appData)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.statesPath, (state, index) => {
                    return (_openBlock(), _createBlock(_component_application_step, {
                      key: index,
                      route: state.route,
                      application: _ctx.appData,
                      path: _ctx.path,
                      loading: _ctx.loading,
                      "previous-loading": _ctx.previousLoading,
                      "is-current": _ctx.isCurrent(state),
                      onPrevious: _ctx.previous,
                      onComplete: _ctx.stepComplete
                    }, {
                      default: _withCtx(() => [
                        (_ctx.loading)
                          ? (_openBlock(), _createBlock(_component_circle_loader, {
                              key: 0,
                              inverse: true
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["route", "application", "path", "loading", "previous-loading", "is-current", "onPrevious", "onComplete"]))
                  }), 128))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["step-scroll"]))
    ]),
    _: 2
  }, [
    (_ctx.appData)
      ? {
          name: "ad",
          fn: _withCtx(() => [
            _createElementVNode("h1", null, _toDisplayString(_ctx.leftPanelText), 1)
          ])
        }
      : undefined
  ]), 1024))
}