export enum Products {
  LOC = 1,
  REFI = 2,
}

export enum ApplicationStatuses {
  NOT_SUBMITTED = 1,
  EVALUATION = 2,
  SUBMITTED_AND_PROCESSING = 3,
  COMPLETE = 4,
  DECLINED = 5,
  CANCELLED = 6,
  SMR_DECLINED = 7,
  RIGHT_TO_CANCEL = 8,
}

export enum ApplicantTypes {
  PRIMARY_APPLICANT = 1,
  CO_APPLICANT = 2,
}

export enum RateTypes {
  Fixed = 1,
  Variable = 2,
}

export enum ConsentTypes {
  CREDIT = 1,
  ECOMMUNICATION = 2,
  MOBILE = 3,
  TERMS_AND_CONDITIONS = 4,
  EMAIL_VALIDATION = 5,
  SOFT_PULL = 6,
  REFI_UNDERPAYMENT = 7,
}
