import { Module } from "vuex";
import { $api } from "@/services/api";
import store from "@/store";
import useFormatter from "@/use/formatter";
import useFormHelpers from "@/use/formHelpers";

const { formatDateOfBirth } = useFormatter();
const { compareTwoDates } = useFormHelpers();

const applicantPersonalInformationModule: Module<any, any> = {
  namespaced: true,
  state: {
    currentPersonalInfo: {} as any,
    sameDOB: undefined as undefined | boolean,
    sameSSN: undefined as undefined | boolean,
    meetsAOM: undefined as undefined | boolean,
    applicantState: undefined as any,
    applicantAge: undefined as undefined | number,
    applicantDateOfBirth: undefined as undefined | Date | string,
  },
  mutations: {
    SET_APPLICANT_STATE(state: any, applicantState: any) {
      state.applicantState = applicantState;
    },
    SET_CURRENT_PERSONAL_INFO(state: any, personalInfo: any) {
      state.currentPersonalInfo = personalInfo;
    },
    SET_SAME_DOB(state: any, flag: boolean) {
      state.sameDOB = flag;
    },
    SET_SAME_SSN(state: any, flag: boolean) {
      state.sameSSN = flag;
    },
    SET_APPLICANT_AGE(state: any, age: boolean) {
      state.applicantAge = age;
    },
    SET_APPLICANT_DATE_OF_BIRTH(state: any, dob: string) {
      state.applicantDateOfBirth = dob;
    },
    RESET_ALL_STATES(state: any) {
      state.currentPersonalInfo = {};
      state.sameDOB = undefined;
      state.sameSSN = undefined;
      state.meetsAOM = undefined;
      state.applicantState = undefined;
      state.applicantAge = undefined;
      state.applicantDateOfBirth = undefined;
    },
  },
  actions: {
    async compareDateOfBirths({ state, commit }) {
      const dob1 = new Date(formatDateOfBirth(store?.state?.appData?.applicants[0]?.dateOfBirth));
      const dob2 = new Date(state.applicantDateOfBirth);

      const compare = compareTwoDates(dob1, dob2);
      commit("SET_SAME_DOB", compare);
    },
  },
  getters: {
    ValidApplicantAge(state: any) {
      return state.applicantAge && state.applicantAge > 0 && state.applicantAge <= 150;
    },
    SameDOB(state: any) {
      return state.sameDOB;
    },
    SameSSN(state: any) {
      return state.sameSSN;
    },
    ApplicantAge(state: any) {
      return state.applicantAge;
    },
    ApplicantDateOfBirth(state: any) {
      return state.applicantDateOfBirth;
    },
    AgeOfMajority(state: any) {
      let ageOfMajority = 18;
      switch (state.applicantState) {
        case "1":
        case "AL":
        case 1:
          return (ageOfMajority = 19);
        case "46":
        case "PR":
        case 46:
          return (ageOfMajority = 21);
        default:
          return (ageOfMajority = 18);
      }
    },
    AgeOfMajorityError(state: any, getters: any) {
      return getters.AgeOfMajority > getters.ApplicantAge && getters.ApplicantAge > 0 && getters.ApplicantAge <= 150;
    },
    AgeOfMajorityErrorMessage(state: any, getters: any) {
      const coApplicantTypeDisplayName = store.getters["coApplicantTypeDisplayName"]?.toLowerCase();
      const currentRoute = store.state?.appData?.currentState?.route;

      switch (currentRoute) {
        case "borrowerPersonalInfo":
        case "refiBorrowerPersonalInfo":
          return `Borrowers who do not meet the Age of Majority should add a ${coApplicantTypeDisplayName} to qualify.`;
        case "borrowerInfo":
        case "refiBorrowerInfo":
          return "";
        case "coborrowerInfo":
        case "coborrowerPersonalInfo":
        case "coborrowerInvitedApplicantInfo":
        case "refiCosignerInfo":
        case "refiCosignerPersonalInfo":
        case "refiCosignerInvitedApplicantInfo":
          return `The ${coApplicantTypeDisplayName} must meet the Age of Majority to qualify.`;
        default:
          return "";
      }
    },
  },
};

export default applicantPersonalInformationModule;
