import { Products, ApplicationStatuses, ApplicantTypes, RateTypes, ConsentTypes } from "@/config/enums";

export class ProductIds {
  public static get LOC(): number {
    return Products.LOC;
  }
  public static get REFI(): number {
    return Products.REFI;
  }
}

export class ApplicationStatusTypeIds {
  public static get NOT_SUBMITTED(): number {
    return ApplicationStatuses.NOT_SUBMITTED;
  }
  public static get EVALUATION(): number {
    return ApplicationStatuses.EVALUATION;
  }
  public static get SUBMITTED_AND_PROCESSING(): number {
    return ApplicationStatuses.SUBMITTED_AND_PROCESSING;
  }
  public static get COMPLETE(): number {
    return ApplicationStatuses.COMPLETE;
  }
  public static get DECLINED(): number {
    return ApplicationStatuses.DECLINED;
  }
  public static get CANCELLED(): number {
    return ApplicationStatuses.CANCELLED;
  }
  public static get SMR_DECLINED(): number {
    return ApplicationStatuses.SMR_DECLINED;
  }
  public static get RIGHT_TO_CANCEL(): number {
    return ApplicationStatuses.RIGHT_TO_CANCEL;
  }
}

export class ApplicantTypeIds {
  public static get PRIMARY_APPLICANT(): number {
    return ApplicantTypes.PRIMARY_APPLICANT;
  }

  public static get CO_APPLICANT(): number {
    return ApplicantTypes.CO_APPLICANT;
  }
}

export class RateTypeIds {
  public static get Fixed(): number {
    return RateTypes.Fixed;
  }

  public static get Variable(): number {
    return RateTypes.Variable;
  }
}

export class ConsentTypeIds {
  public static get CREDIT(): number {
    return ConsentTypes.CREDIT;
  }

  public static get ECOMMUNICATION(): number {
    return ConsentTypes.ECOMMUNICATION;
  }

  public static get MOBILE(): number {
    return ConsentTypes.MOBILE;
  }

  public static get TERMS_AND_CONDITIONS(): number {
    return ConsentTypes.TERMS_AND_CONDITIONS;
  }

  public static get EMAIL_VALIDATION(): number {
    return ConsentTypes.EMAIL_VALIDATION;
  }

  public static get SOFT_PULL(): number {
    return ConsentTypes.SOFT_PULL;
  }
  public static get REFI_UNDERPAYMENT(): number {
    return ConsentTypes.REFI_UNDERPAYMENT;
  }
}

