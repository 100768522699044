
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ModalSmallRefi from "../shared/ModalSmallRefi.vue";

export default defineComponent({
  components: { ModalSmallRefi, FontAwesomeIcon },
  computed: {
    ...mapState(["lenderData"]),
    emailAddress(): string {
      return this.lenderData && this.lenderData.supportEmailAddress
        ? this.lenderData.supportEmailAddress
        : "member_support@studentchoice.org";
    },
    tollFreeNumber(): string {
      return this.lenderData && this.lenderData.tollFreeNumber ? this.lenderData.tollFreeNumber : undefined;
    },
  },
  methods: {
    close(event: any) {
      this.$emit("close", event);
    },
  },
});
