import { Module } from "vuex";
import { $api } from "@/services/api";
import { Applicant } from "@/models/application";
import { ConsentTypeIds, ApplicantTypeIds } from "@/config/constants";
import { ConsentFormRequest } from "@/models/api";

const consentsModule: Module<any, any> = {
  namespaced: true,
  state: {
    consentTypes: [] as any[],
    currentApplicant: {} as Applicant,
    primaryApplicantConsents: [],
    coApplicantConsents: [],
    softPullConsent: undefined as undefined | boolean,
    softPullConsentId: null as null | number,
    ecommConsentId: null as null | number,
    ecommConsent: undefined as undefined | boolean,
  },
  mutations: {
    RESET_STATE(state: any) {
      state = {
        consentTypes: [] as any[],
        currentApplicant: {} as Applicant,
        primaryApplicantConsents: [],
        coApplicantConsents: [],
        softPullConsent: undefined as undefined | boolean,
        softPullConsentId: null as null | number,
        ecommConsentId: null as null | number,
        ecommConsent: undefined as undefined | boolean,
      };
    },
    SET_CURRENT_APPLICANT(state: any, applicant: Applicant) {
      state.currentApplicant = applicant;
    },
    SET_CONSENT_TYPES(state: any, consentTypes: any) {
      state.consentTypes = consentTypes;
    },
    SET_PRIMARY_APPLICANT_CONSENTS(state: any, consents: any) {
      state.primaryApplicantConsents = consents;
    },
    SET_CONSENTS(state: any, consents: any) {
      if (state?.currentApplicant?.applicantTypeId === ApplicantTypeIds.PRIMARY_APPLICANT) {
        state.primaryApplicantConsents = consents;
      } else if (state?.currentApplicant?.applicantTypeId === ApplicantTypeIds.CO_APPLICANT) {
        state.coApplicantConsents = consents;
      } else {
        state.primaryApplicantConsents = [];
        state.coApplicantConsents = [];
      }
    },
    SET_CO_APPLICANT_CONSENTS(state: any, consents: any) {
      state.coApplicantConsents = consents;
    },
    SET_ECOMM_CONSENT_ID(state: any, id?: number) {
      state.ecommConsentId = id;
    },
    SET_ECOMM_CONSENT(state: any, consent: boolean) {
      state.ecommConsent = consent;
    },
    SET_SOFT_PULL_CONSENT_ID(state: any, id?: number) {
      state.softPullConsentId = id;
    },
    SET_SOFT_PULL_CONSENT(state: any, consent: boolean) {
      state.softPullConsent = consent;
    },
  },
  actions: {
    setCurrentApplicantId({ commit }, payload) {
      commit("SET_CURRENT_APPLICANT_ID", payload);
    },
    setCurrentApplicant({ commit }, payload) {
      commit("SET_CURRENT_APPLICANT", payload);
    },
    async saveConsentForm({ state, commit }, payload) {
      let consents = [];
      if (payload?.id && payload?.id > 0) {
        consents = await $api.consents.updateConsent(payload);
      } else {
        consents = await $api.consents.addConsent(payload);
      }

      if (consents && consents?.length > 0) {
        commit("SET_CONSENTS", consents);
      }
    },
    async saveEcommConsent({ state, commit, getters, dispatch }, payload) {
      const ecommunicationsConsentForm = {
        id: state.ecommConsentId,
        consentTypeId: getters["EcommunicationConsentTypeId"],
        consentGiven: payload,
        applicantId: state.currentApplicant?.id || 0,
        applicationId: state.currentApplicant?.applicationId || 0,
        userId: undefined,
      } as ConsentFormRequest;

      await dispatch("saveConsentForm", ecommunicationsConsentForm);
    },
    async saveSoftPullConsent({ state, commit, getters, dispatch }, payload) {
      const softPullConsentForm = {
        id: state.softPullConsentId,
        consentTypeId: getters["SoftPullConsentTypeId"],
        consentGiven: payload,
        applicantId: state.currentApplicant?.id || 0,
        applicationId: state.currentApplicant?.applicationId || 0,
        userId: undefined,
      } as ConsentFormRequest;

      await dispatch("saveConsentForm", softPullConsentForm);
    },
    async getSoftPullConsent({ state, commit, dispatch, getters }, payload) {
      if (state.consentTypes?.length <= 0) {
        await dispatch("getConsentTypes");
      }

      if (getters.Consents?.length === 0) {
        await dispatch("getConsentsByApplicantId");
      }

      const softPullConsent = (getters.Consents || [])?.find((q: any) => q.consentTypeId === ConsentTypeIds.SOFT_PULL);
      commit("SET_SOFT_PULL_CONSENT_ID", softPullConsent?.id);
      commit("SET_SOFT_PULL_CONSENT", softPullConsent?.consentGiven);
    },
    async getEcommConsent({ state, commit, dispatch, getters }, payload) {
      if (state.consentTypes?.length <= 0) {
        await dispatch("getConsentTypes");
      }

      if (getters.Consents?.length === 0) {
        await dispatch("getConsentsByApplicantId");
      }

      const ecomm = (getters.Consents || [])?.find((q: any) => q.consentTypeId === ConsentTypeIds.ECOMMUNICATION);
      commit("SET_ECOMM_CONSENT_ID", ecomm?.id);
      commit("SET_ECOMM_CONSENT", ecomm?.consentGiven);
    },
    async getConsentsByApplicantId({ state, commit }, payload) {
      const applicantId = state.currentApplicant?.id || 0;
      const consents = await $api.consents.getAllConsents(applicantId);
      if (consents && consents?.length > 0) {
        commit("SET_CONSENTS", consents);
      }
    },
    async getConsentTypes({ state, commit }) {
      try {
        if (state.consentTypes?.length <= 0) {
          const lookups = await $api.lookups.getConsentTypes();
          if (lookups?.length > 0) {
            commit("SET_CONSENT_TYPES", lookups);
          }
        }
      } catch (error) {
        console.log("Error: getConsentTypes()", error);
      }
    },
  },
  getters: {
    Consents: (state: any): any => {
      if (state?.currentApplicant?.applicantTypeId === ApplicantTypeIds.PRIMARY_APPLICANT) {
        return state.primaryApplicantConsents;
      } else if (state?.currentApplicant?.applicantTypeId === ApplicantTypeIds.CO_APPLICANT) {
        return state.coApplicantConsents;
      } else {
        return [];
      }
    },
    EcommunicationConsentTypeId: (state: any): number => {
      return state?.consentTypes?.find((ct: any) => ct.id === ConsentTypeIds.ECOMMUNICATION)?.id;
    },
    SoftPullConsentTypeId: (state: any): number => {
      return state?.consentTypes?.find((ct: any) => ct.id === ConsentTypeIds.SOFT_PULL)?.id;
    },
  },
};

export default consentsModule;
