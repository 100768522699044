import { $api } from "@/services/api";
import store from "@/store";

export default function useApplication() {
  async function getLenderConfigurationByAppId(
    appId: number,
    lenderId?: number,
    productTypeId?: number,
    programTypeId?: number,
  ) {
    let response = {} as any;
    let lenderConfig = {} as any;

    // If programTypeId has been set - supports refi case
    if (lenderId && productTypeId && programTypeId) {
      lenderConfig = await $api.configuration.getLenderConfiguration(lenderId, programTypeId, productTypeId);
      store.dispatch("setLenderData", lenderConfig);
      return lenderConfig;
    } else {
      // Handle default case
      response = await $api.applications.getApplication(appId);
      if (response && response?.application) {
        if (response?.application?.programTypeId) {
          lenderConfig = await $api.configuration.getLenderConfiguration(
            response?.application?.lenderId,
            response?.application?.programTypeId,
            response?.application?.productTypeId,
          );
        } else {
          lenderConfig = await $api.configuration.getLenderConfigurationByLenderId(response?.application?.lenderId);
        }

        store.dispatch("setLenderData", lenderConfig);
        return lenderConfig;
      } else {
        return [];
      }
    }
  }
  return {
    getLenderConfigurationByAppId,
  };
}
