import { Module } from "vuex";
import { $api } from "@/services/api";
import store from "@/store";

const loanConfigurationModule: Module<any, any> = {
  namespaced: true,
  state: {
    currentSubStep: 1,
    redirectToPortal: undefined as undefined | boolean,
    isEditing: false as boolean,
    initialDrawRequest: undefined as any,
		selectedRatePlan: undefined as any,
		selectedEnrollmentTerm: {} as any,
		info: {
			interestRate: undefined as undefined | number,
			rateTypeId: undefined as undefined | number,
			rateTypeName: undefined as undefined | string,
			schoolUndecided: undefined as undefined | boolean,
			academicTerm: undefined as undefined | boolean,
			enrollmentTermId: undefined as undefined |  number,
			graduationDate: undefined as undefined | Date,
			graduationDateYear: undefined as undefined | number,
			graduationDateMonth: undefined as undefined | number,
			school: {} as any,
			requestedAmount: undefined as undefined| number,
			loanAmount: undefined as undefined| number,
		} as any,
		enrollmentTerms: [] as any[],
		schoolConfiguration: {} as any
  },
  actions: {
		isCurrentSubStep({ state, commit }, payload) {
			return state.currentSubStep === payload;
		},
		setCurrentSubStep({ commit }, payload) {
			commit("SET_CURRENT_SUBSTEP", payload);
		},
		setInitialDrawRequest({ commit }, payload) {
			commit("SET_INITIAL_DRAW_REQUEST", payload);
		},
		setSelectedRatePlan({ commit }, payload) {
			commit("SET_SELECTED_RATE_PLAN", payload);

		}
	},
  mutations: {
		SET_CURRENT_SUBSTEP(state: any, substep: number) {
      state.currentSubStep = substep;
    },
		SET_INITIAL_DRAW_REQUEST(state: any, initialDrawRequest: any) {
			state.initialDrawRequest = initialDrawRequest;
		},
		SET_SELECTED_RATE_PLAN(state: any, selectedRatePlan: any) {
			state.selectedRatePlan = selectedRatePlan;
		},
		SET_SCHOOL_CONFIGURATION() {
			// store.state.appData?.application?.schoolConfiguration?.academicTerm
		}
	},
  getters: {
    stepScroll: (state: any): string => {
      return `${(state.currentSubStep - 1) * 100}%`;
    },
		// enrollmentTerm: (state: any): EnrollmentTerm => {
		// 	return store.state.enrollmentTerms.find(() => appData?.application?.schoolConfiguration?.academicTerm)
		// },
  }
};

export default loanConfigurationModule;
