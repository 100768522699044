import dayjs from "dayjs";

export default function useFormatter(): any {
  function formatNumber(number: number, decimals = 2): string {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(number);
  }

  function formatMoney(number: number, decimals = 2): string {
    const value = formatNumber(number, decimals);
    if (value.charAt(0) === "-") {
      return `-$${value.replace("-", "")}`;
    } else {
      return `$${value}`;
    }
  }

  function formatDateOfBirth(dob: any) {
    if (dob) {
      const [date, time] = dob.split("T");
      return date;
    }

    return dob;
  }

  function formatDate(dateStr: string, format = "MMMM D, YYYY"): string {
    try {
      return dayjs(dateStr).format(format);
    } catch (ex) {
      console.log("Could not format date");
      return dateStr;
    }
  }

  function formatDateYYYYMMDD(dateStr: string, format = "YYYY/MM/DD"): string {
    try {
      return dayjs(dateStr).format(format);
    } catch (ex) {
      console.log("Could not format date");
      return dateStr;
    }
  }

  function formatZipCode(zipStr: string): string {
    if (zipStr) {
      const cleanedZip = zipStr?.includes("-") ? zipStr.split("-").join("") : `${zipStr}`;
      return cleanedZip.length === 9 ? `${cleanedZip.substring(0, 5)}-${cleanedZip.substring(5, 9)}` : zipStr;
    } else return "";
  }
  function formatPhoneNumber(phoneStr: string): string {
    const cleanedPhone = phoneStr.replace(/[^0-9]/g, "");

    return cleanedPhone.length === 10
      ? `(${cleanedPhone.substring(0, 3)}) ${cleanedPhone.substring(3, 6)}-${cleanedPhone.substring(6, 10)}`
      : phoneStr;
  }

  function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function componentToHex(c: number) {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  function formatRgbToHex(r: number, g: number, b: number) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  function formatHexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  function stringToBoolean(stringValue: string) {
    switch (stringValue?.toLowerCase()?.trim()) {
      case "true":
      case "yes":
      case "1":
        return true;

      case "false":
      case "no":
      case "0":
      case null:
      case undefined:
        return false;

      default:
        return JSON.parse(stringValue);
    }
  }

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, "0");
  }

  function formatSchoolBranchDOEId(stringValue: string) {
    switch (stringValue?.length) {
      case 1:
        return stringValue.padStart(2, "0");
      default:
        return stringValue;
    }
  }

  function formatSchoolDOEId(stringValue: string) {
    switch (stringValue?.length) {
      case 4:
        return stringValue.padStart(6, "00");
      case 5:
        return stringValue.padStart(6, "0");
      default:
        return stringValue;
    }
  }

  function formatName(input: string) {
    // this capitalizes each word of a string
    return input
      .split(" ")
      .map((word: string) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
  }

  function formatMaskedSSN(maskedSsn: string) {
    const errorObj = {
      error: true,
      errorMessage: "Invalid ssn format",
    };

    if (typeof maskedSsn !== "string") {
      return errorObj;
    }

    const [mask, lastFour] = maskedSsn.split("*****");

    if (lastFour.length === 0) {
      return errorObj;
    }

    const formattedSSN = "XXX-XX-" + lastFour;

    return formattedSSN;
  }

  return {
    formatNumber,
    formatMoney,
    formatDate,
    formatDateOfBirth,
    formatDateYYYYMMDD,
    formatZipCode,
    formatPhoneNumber,
    formatBytes,
    formatHexToRgb,
    formatRgbToHex,
    stringToBoolean,
    padTo2Digits,
    formatSchoolBranchDOEId,
    formatSchoolDOEId,
    formatName,
    formatMaskedSSN,
  };
}
